import { useState } from 'react';
import { Card, CardContent, CardHeader, Link, Typography, CircularProgress, IconButton, Button, Box } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from './MajorIncidentsInput'
import { useCurrentUserInfo } from "../DataHooks/useCurrentUserInfo";
import { TempServiceAlerts, ServiceNowTicket } from "../../apiInterfaces";

type MajorIncidentsDisplayProps = {
  isLoading: boolean;
  onEdit: (alert: TempServiceAlerts) => void;
  incidentList: (TempServiceAlerts | ServiceNowTicket)[];
  type: "active" | "closed";
};

const MajorIncidentsDisplay: React.FC<MajorIncidentsDisplayProps> = ({ isLoading, onEdit, incidentList, type }) => {
  let { is_admin_user } = useCurrentUserInfo();
  const [visibleComments, setVisibleComments] = useState<string | null>(null);

  const handleCommentsToggle = (id: string | null) => {
    setVisibleComments((prevVisibleComments) => (prevVisibleComments === id ? null : id));
  };

  const handleUpdateClick = (incident: TempServiceAlerts) => {
    onEdit(incident);
  };

  const getUpdateTime = (incident: TempServiceAlerts | ServiceNowTicket) => {
    if (isTempServiceAlert(incident)) {
      if (incident.comments.length > 0) {
        const lastComment = incident.comments[incident.comments.length - 1];
        return (lastComment.timestamp ? formatDate(new Date(lastComment.timestamp)) : 'Unknown Time');
      }
      return 'Unknown Time';
    } else {
      return (incident.sys_updated_on ? formatDate(new Date(incident.sys_updated_on)) : 'Unknown Time');
    }
  };

  return (
    <Card variant="outlined">
      <CardContent sx={{ position: "sticky", top: 0, zIndex: 1, padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          {type === 'active' ? "Active Major Incidents" : "Recently Resolved Major Incidents"}
        </Typography>
      </CardContent>
      <CardContent sx={{ maxHeight: '450px', overflowY: 'auto', padding: 2 }}>
        {isLoading ? (
          <Card variant="outlined" sx={{ mb: 2, display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
            <CircularProgress />
          </Card>
        ) : incidentList.length === 0 ? (
          <Card variant="outlined" sx={{ mb: 2, display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
            <CardHeader
              avatar={<CheckCircleIcon sx={{ color: "green" }} />}
              title={
                <Typography variant="h6" color="inherit">
                  All Clear :D
                </Typography>
              }
            />
          </Card>
        ) : (
          incidentList.map((incident, index) => (
            <Card
              key={`${incident.short_description}_${incident.sys_created_on}`}
              variant="outlined"
              sx={{
                mb: 2,
                borderLeft: type === 'active' ? "4px solid orange" : "4px solid green",
                position: "relative",
                zIndex: visibleComments === `${incident.short_description}_${incident.sys_created_on}` ? incidentList.length : index,
                transition: "margin-top 0.3s ease-in-out, z-index 0.3s ease-in-out",
              }}
            >
              <CardHeader
                avatar={
                  type === 'active' 
                  ? <WarningIcon sx={{ color: "orange" }} />
                  : <CheckCircleIcon sx={{ color: "green" }} />
                }
                title={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CardContent sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Link 
                          href={isServiceNowTicket(incident) ? `https://uci.service-now.com/now/nav/ui/classic/params/target/incident.do%3Fsys_id%3D${incident.sys_id}` : undefined}
                          variant="h6" 
                          color="inherit" 
                          underline="hover"
                        >
                          {incident.short_description}
                        </Link>
                        {type === 'active' && isTempServiceAlert(incident) && is_admin_user && (
                          <IconButton onClick={() => handleUpdateClick(incident)} sx={{ ml: 'auto' }}>
                            <EditIcon fontSize='large' />
                          </IconButton>
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                          <Typography variant="body2">
                            Opened At: {incident.opened_at ? formatDate(new Date(incident.opened_at)) : 'Unknown Time'}
                          </Typography>
                          <Typography variant="body2">
                            {type === 'active' ? 'Updated On: ' : 'Closed At: '}
                            {getUpdateTime(incident)}
                          </Typography>
                        </Box>

                        <Button 
                          variant="outlined" 
                          onClick={() => handleCommentsToggle(`${incident.short_description}_${incident.sys_created_on}`)}
                          sx={{ ml: 'auto' }}
                        >
                          {visibleComments === `${incident.short_description}_${incident.sys_created_on}` ? 'Hide Comments' : 'See Comments'}
                        </Button>
                      </Box>
                    </CardContent>
                  </Box>
                }
              />
                {visibleComments === `${incident.short_description}_${incident.sys_created_on}` && (
                  <CardContent>
                    {isServiceNowTicket(incident) ? (
                      <Typography variant="body2" color="textSecondary">
                        {incident.comments}
                      </Typography>) : 
                      incident.comments.map(comment => (
                      <div key={comment.content}>
                        <Typography variant="body2" color="textSecondary">
                          {comment.timestamp ? formatDate(new Date(comment.timestamp)) : 'Unknown Time'} - {comment.author}: {comment.content}
                        </Typography>
                      </div>
                    ))}
                  </CardContent>
                )}
            </Card>
          ))
        )}
      </CardContent>
    </Card>
  );
};

const isServiceNowTicket = (incident: any): incident is ServiceNowTicket => {
  return (incident as ServiceNowTicket).sys_id !== 'SYSTEM';
};

const isTempServiceAlert = (incident: any): incident is TempServiceAlerts => {
  return (incident as TempServiceAlerts).sys_id === 'SYSTEM';
};

export default MajorIncidentsDisplay;
