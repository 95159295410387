export interface ADAttributes {
    objectClass?: string[]
    cn?: string
    sn?: string
    givenName?: string
    distinguishedName?: string
    badPwdCount?: number
    badPasswordTime?: string
    instanceType?: number
    whenCreated?: string
    whenChanged?: string
    displayName?: string
    uSNCreated?: number
    lockoutTime?: string
    memberOf?: string[]
    uSNChanged?: number
    proxyAddresses?: string[]
    publicDelegatesBL?: string[]
    targetAddress?: string
    mailNickname?: string
    name?: string
    objectGUID?: string
    userAccountControl?: number
    codePage?: number
    countryCode?: number
    lastLogon?: string
    pwdLastSet?: string
    primaryGroupID?: number
    objectSid?: string
    accountExpires?: string
    logonCount?: number
    sAMAccountName?: string
    sAMAccountType?: number
    showInAddressBook?: string[]
    legacyExchangeDN?: string
    userPrincipalName?: string
    objectCategory?: string
    dSCorePropagationData?: string[]
    lastLogonTimestamp?: string
    msTSExpireDate?: string
    msTSLicenseVersion?: string
    msTSManagingLS?: string
    msTSLicenseVersion2?: string
    msTSLicenseVersion3?: string
    "msDS-ExternalDirectoryObjectId"?: string
    textEncodedORAddress?: string
    mail?: string
    msExchPoliciesExcluded?: string[]
    msExchRecipientTypeDetails?: number
    msExchVersion?: number
    msExchRecipientDisplayType?: number
    msExchRemoteRecipientType?: number
}

export interface ADInfo {
    raw_dn: string
    dn: string
    attributes: ADAttributes
    type: string
}

export const enum AdobeLicense {
    CreativeCloud = "creative_cloud",
    AcrobatDC = "acrobat_dc",
    None  = "none",
}

export const enum AdobeLicenseEligibilityReason{
    //Eligible
    ACTIVE_STUDENT = 100,
    LAW_STUDENT = 101,
    ACTIVE_EMPLOYEE_IRVN = 200,
    ACTIVE_STUDENT_EMPLOYEE = 201,
    ACTIVE_GUEST_IRVN = 300,

    //Ineligible
    INACTIVE_EMPLOYEE = 400,
    INACTIVE_STUDENT = 401,
    ACTIVE_EMPLOYEE_HS = 402,
    INACTIVE_GUEST = 415,
    APPLICANT = 410,
    UNDEFINED = 500
}

export interface AdobeLicenseEligibility{
    eligible: boolean,
    reason: AdobeLicenseEligibilityReason,
    reason_name: string,
    reason_detail: string
}

export interface AdobeLicenseInfo{
    eligibility_result: AdobeLicenseEligibility,
    license_type: AdobeLicense
    enrolled_terms: string[]
}

export interface AdobeLicenseRequestInfo{
    ucinetid: string
    reason: string
    expiration_date: string | null
    requested_license: AdobeLicense
}

export const enum ALMEventType{
    LICENSE_ASSIGNED = "LICENSE ASSIGNED",
    LICENSE_REVOKED = "LICENSE REVOKED",
    MARKED_INELIGIBLE = "MARKED INELIGIBLE",
    NOTIFICATION_EMAIL_SENT = "NOTIFICATION EMAIL SENT",
    REVOCATION_CANCELED = "REVOCATION CANCELED",
    LICENSE_REASSIGNED = "LICENSE REASSIGNED" //Obsolete
}

//ALM logs
export interface ALMEvent{
    type: ALMEventType
    detail: string
    operator: string
    timestamp: string
    ucinetid?: string
}

export interface ALMOperators{
    operator: string,
    count: number
}

export const AffiliationOrder = [
    "group",
    "service_account",
    "retiree",
    "faculty",
    "employee",
    "alumni",
    "student",
    "advanced_degree_holder",
    "degree_holder",
    "former_student",
    "applicant",
    "guest",
    "staff",
    "contingent_worker",
    "tpid (third-party id)",
] as const

export type Affiliation = typeof AffiliationOrder[number]

export interface BlockListRecord {
    who: string
    when: string
    what: string
    wher: string
    why: string
    how: string
}

export interface Building {
    id: number
    compco_id: string
    abbr_name: string
    address_911: string
    last_updated: string
    alias: string
    published_flag: "0" | "1"
}

export interface CSCUserData{
    ucinetid: string
}

export interface DepartmentSupportData {
    department_number: string
    support_groups: SupportGroup[]
    log: SupportGroupLog[]
}

export interface FirewallRule {
    id: number
    created_on: string
    updated_on: string
    updated_by: string
    ucinetid: string
    campusid: string
    dept_code: string
    responsible_ucinetid: string
    responsible_campusid: string
    responsible_dept_code: string
    status: string
    type: string
    host: string
    ports_open_tcp: string
    ports_open_udp: string
    comments: string
}

export interface ForescoutFilteredHost {
    hostId: number
    ip: string
    mac: string
}

export interface ForescoutHost {
    host?: {
        ip?: string
        mac?: string
        id?: number
        fields?: {
            channel?: ForescoutHostField
            onsite?: ForescoutHostField
            openports?: ForescoutHostField[]
            mac_vendor_string?: ForescoutHostField
            hostname?: ForescoutHostField
            fingerprint?: ForescoutHostField
            user_def_fp?: ForescoutHostField
            "in-group"?: ForescoutHostField[]
            dhcp_class_v2?: ForescoutHostField
            dhcp_class?: ForescoutHostField
            dex_MobileAccess?: ForescoutHostField
            dex_MobileAccessUser?: ForescoutHostField
            bdomain?: ForescoutHostField
            dhcp_hostname?: ForescoutHostField
            dhcp_server?: ForescoutHostField
            dhcp_os_v2?: ForescoutHostField
            vendor?: ForescoutHostField
            ebanner_http?: ForescoutHostField[]
            dhcp_hostname_v2?: ForescoutHostField
            online?: ForescoutHostField
            _times?: ForescoutHostField[]
            p0f_fingerprint?: ForescoutHostField[]
            va_netfunc?: ForescoutHostField
            engine_seen_packet?: ForescoutHostField
        }
    }
}

interface ForescoutHostField {
    timestamp: number
    value: string
}

export interface JPLPort {
    station_id: string
    circuit_id: string
    building: string
    room: string
    subnet: string
    port: string
    contact: string
    voip_phone_no: string
    notes: string
    order_no: string
    entered_by: string
}

export interface JPLSubnet {
    building: string
    router: string
    router_additional_details: string
    interface: string
    network_address: string
    network_usage: string
    jpl_network_name: string
    notes: string
}

export interface KFSOrg {
    org_chart_code: string
    org_chart_code_long_description: string
    department_number: string
    org_name: string
    org_manager_id: string
    org_manager_user_id: string
    org_manager_name: string
    org_responsibility_code: string
    org_responsibility_long_description: string
    org_responsibility_short_description: string
    org_type_code: string
    org_type_long_description: string
    org_default_account_number: string
    org_default_account_long_description: string
    org_parent_chart_code: string
    org_parent_code: string
    org_parent_name: string
    org_begin_date: string
    org_end_date: string
}

export interface KFSOrgContact{
    department_number: string
    phone_numbers: string[]
    email_addresses: string[]
    websites: string[]
}

export interface LDAPAttributes {
    cn?: string
    createTimestamp?: string
    department?: string[]
    departmentNumber?: string[]
    displayName?: string
    dn?: string
    eduPersonAffiliation?: string[]
    eduPersonOrgDN?: string
    eduPersonPrincipalName?: string
    eduPersonPrincipalNamePrior?: string[]
    eduPersonScopedAffiliation?: string[]
    employeeNumber?: string
    facsimileTelephoneNumber?: string[]
    givenName?: string
    l?: string
    mail?: string
    major?: string
    memberOf?: string[]
    middleName?: string
    modifyTimestamp?: string
    myDotName?: string
    o?: string
    objectClass?: string[]
    ou?: string
    postalAddress?: string[]
    postalCode?: string
    sn?: string
    st?: string
    telephoneNumber?: string[]
    title?: string[]
    uciAdminAppCSS?: string
    uciAffiliation?: Affiliation[]
    uciApplicantEmail?: string
    uciCampusID?: string
    uciCTOCode?: string[]
    /** @deprecated Use uciPrimaryTitle instead*/
    uciDivision?: string
    /** @deprecated Use uciPrimaryTitleCode instead*/
    uciDivisionCode?: string
    uciEmployeeClass?: string[]
    uciEmployeeClassDescription?: string[]
    uciEmployeeGivenName?: string
    uciEmployeeMiddleName?: string
    uciEmployeeSN?: string
    uciEmployeeStatus?: string
    uciEmployeeStatusCode?: string
    uciFloater?: string
    uciGuestExpiration?: string
    uciPrimaryDepartment?: string
    uciPrimaryDepartmentCode?: string
    uciPrimaryDepartmentCodeTitle?: string
    uciHrStatus?: string
    uciKFSCampusCode?: string
    uciKFSChart?: string
    uciKFSChartOrgCode?: string
    uciKFSChartOrgName?: string
    uciKFSOrgCode?: string
    uciLastUpdateDate?: string
    uciLevel2DepartmentID?: string
    uciLevel2DepartmentDescription ?: string
    uciLevel3DepartmentID?: string
    uciLevel3DepartmentDescription ?: string
    uciLevel4DepartmentID?: string
    uciLevel4DepartmentDescription ?: string
    uciMailDeliveryPoint?: string
    ucinetidLocked?: string
    ucinetidLockedAt?: string
    ucinetidPasswordChangedAt?: string
    ucinetidReset?: string
    ucinetidResetAt?: string
    uciOrganization?: string
    uciOrganizationCode?: string
    uciPrimaryCTOCode?: string
    uciPrimaryEmployeeClass?: string
    uciPrimaryEmployeeClassDescription?: string
    uciPrimaryTitle?: string
    uciPrimaryTitleCode?: string
    uciPublishFlag?: string
    uciRecentlyHired?: string
    uciReleaseFlag?: string
    uciSponsorDepartment?: string
    uciSponsorDepartmentCode?: string
    uciSponsorID?: string
    uciStudentEmailRelease?: string
    uciStudentGivenName?: string
    uciStudentID?: string[]
    uciStudentLevel?: string
    uciStudentMajor?: string
    uciStudentMajorCode?: string
    uciStudentMiddleName?: string
    uciStudentSN?: string
    /** @deprecated Use <uciLevel2, 3, 4 DepartmentID> instead*/
    uciSubDivision?: string
    /** @deprecated Use <uciLevel2, 3, 4 DepartmentDescription> instead*/
    uciSubDivisionCode?: string

    uciSupervisorDN?: string
    uciSupervisorRoleFlag?: string
    uciTestAccount?: string
    uciUCNetID?: string
    uciVPNFlag?: string
    uciZotCode?: string
    uciZotCodeName?: string
    uid?: string[]
    userPassword?: string
    uciAlumniMailAlias?: string
    uciAlumniMailDeliveryPoint?: string
}

export interface LDAPEntry {
    attributes: LDAPAttributes
    dn: string
    type: string
}

export interface LDAPForcedAttributes {
    uciPrimaryTitle: string | []
    uciPrimaryDepartment: string | []
    uciStudentLevel: string | []
    uciStudentMajor: string | []
    uciMailDeliveryPoint: string | []
    uciAffiliation: Affiliation[]
    displayName: string | []
    uid: string[]
}

export interface MACVendorInfo {
    company: string
}

export interface ModelerLicenseDetail{
    expiration_date: string,
    assigned_by: string,
    assignment_time: string,
    has_accepted_tos: boolean,
    assignment_reason?: string
}

export interface ModelerLicenseInfo{
    eligibility_result: boolean,
    license_info?: ModelerLicenseDetail
}


export interface ModelerLicenseRequestInfo{
    ucinetid: string
    reason: string
}

export interface NetworkRegistration {
    mac_address: string
    responsible_ucinetid: string
    created_DTS: string
    last_seen: string | null
    comments: string
    vendor?: MACVendorInfo | null
}

export interface NSLookupInfo {
    host_name: string
    aliases: string[]
    ip_addresses: string[]
}

export interface QuickTempInfo {
    department_number: string
    org: string
    supervisor_name: string
    supervisor_phone: string
    supervisor_ucinetid: string
    ucinetid: string
}

export interface ResNetNATMapping {
    private_ip: string
    public_ip: string
    ports: number[]
}

export interface SupportGroup {
    name: string
    supported_department_numbers: string[]
    contacts: SupportGroupContact[]
    notes: string | null | undefined
}

export interface SupportGroupContact {
    name: string
    phone_number: string
    phone_number_private: boolean
    email: string
    main_contact: boolean
}

export interface SupportGroupLog {
    department_number: string
    department_name: string
    message: string
}

export interface SubnetsMetadataInterface {
    last_updated: string
    data_set_name: string
}

export interface VisitorNetworkRegistration {
    known_visitors_id: number
    created_DTS: string // 2021-08-19T09:41:13
    last_seen: string | null
    expires: string
    mac_address: string
    visitor_registration_log_id: number
    registration_DTS: string
    name: string
    email_address: string
    reason: string
    reason_other: string | null
}

export interface ServiceNowTicket {
    caused_by: string | null,
    watch_list: string | null,
    upon_reject: string | null,
    sys_updated_on: string | null,
    origin_table: string | null,
    approval_history: string | null,
    skills: string | null,
    number: string | null,
    u_monitoring_incident_id: string | null,
    state: string | null,
    x_caci_cisco_dna_360_analysis: string | null,
    sys_created_by: string | null,
    knowledge: string | null,
    order: string | null,
    u_restrict_access: string | null,
    cmdb_ci: string | null,
    contract: string | null,
    impact: string | null,
    active: string | null,
    x_caci_cisco_dna_cisco_dna_comments: string | null,
    x_caci_cisco_dna_cisco_dna_mac_address: string | null,
    x_caci_cisco_dna_suggested_actions: string | null,
    priority: string | null,
    u_uci_resnet_community2: string | null,
    business_duration: string | null,
    group_list: string | null,
    short_description: string | null,
    correlation_display: string | null,
    work_start: string | null,
    u_external_incident: string | null,
    additional_assignee_list: string | null,
    notify: string | null,
    sys_class_name: string | null,
    closed_by: string | null,
    follow_up: string | null,
    parent_incident: string | null,
    reopened_by: string | null,
    x_caci_cisco_dna_cisco_dna_center_ip_address: string | null,
    reassignment_count: string | null,
    x_caci_cisco_dna_network_userid: string | null,
    assigned_to: string | null,
    u_restricted_group_list: string | null,
    sla_due: string | null,
    x_caci_cisco_dna_u_string_1: string | null,
    x_caci_cisco_dna_u_string_2: string | null,
    escalation: string | null,
    upon_approval: string | null,
    correlation_id: string | null,
    u_uci_incident_building: string | null,
    x_caci_cisco_dna_cisco_dna_suggested_actions_commands_output: string | null,
    made_sla: string | null,
    x_caci_cisco_dna_cisco_360_view: string | null,
    sn_esign_document: string | null,
    child_incidents: string | null,
    task_effective_number: string | null,
    resolved_by: string | null,
    u_restricted_user_list: string | null,
    sys_updated_by: string | null,
    opened_by: string | null,
    user_input: string | null,
    sys_created_on: string | null,
    sys_domain: string | null,
    route_reason: string | null,
    calendar_stc: string | null,
    closed_at: string | null,
    u_bill_to: string | null,
    business_service: string | null,
    business_impact: string | null,
    rfc: string | null,
    time_worked: string | null,
    expected_start: string | null,
    opened_at: string | null,
    u_external_system_id: string | null,
    work_end: string | null,
    caller_id: string | null,
    reopened_time: string | null,
    resolved_at: string | null,
    subcategory: string | null,
    close_code: string | null,
    assignment_group: string | null,
    business_stc: string | null,
    cause: string | null,
    description: string | null,
    u_uci_incident_floor: string | null,
    origin_id: string | null,
    calendar_duration: string | null,
    close_notes: string | null,
    sys_id: string | null,
    contact_type: string | null,
    sn_esign_esignature_configuration: string | null,
    u_alt_contact_information: string | null,
    incident_state: string | null,
    urgency: string | null,
    problem_id: string | null,
    activity_due: string | null,
    severity: string | null,
    x_caci_cisco_dna_cisco_dna_created: string | null,
    comments: string | null,
    u_uci_incident_jack: string | null,
    u_uci_incident_room: string | null,
    approval: string | null,
    due_date: string | null,
    sys_mod_count: string | null,
    reopen_count: string | null,
    sys_tags: string | null,
    location: string | null,
    u_incident_appointment: string | null,
    category: string | null,
    u_effort_type: string | null
}

export interface ZoomTicket {
    number: string;
    link: string | null;
    description: string | null;
    caller: string | null;
    zoom_time: string | null;
}

export interface TempServiceAlertsComment {
    timestamp: string | null;
    author: string | null;
    content: string | null;
}

export interface TempServiceAlerts {
    sys_id: string;
    short_description: string | null;
    cmdb_ci: string | null;
    active: boolean;
    comments: TempServiceAlertsComment[];
    sys_created_on: string | null;
    sys_updated_on: string | null;
    opened_at: string | null;
    resolved_at: string | null;
    closed_at: string | null;
}