import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, } from "@mui/material";
import PageParent from "../PageStructure/PageParent/PageParent";
import ToolHeader from "../GenericBlocks/ToolHeader";
import { useCurrentUserInfo } from "../DataHooks/useCurrentUserInfo"
import { TempServiceAlerts } from '../../apiInterfaces';
import ActiveMajorIncidents from "./ActiveMajorIncidents";
import ClosedMajorIncidents from "./ClosedMajorIncidents";
import MajorIncidentsInput from "./MajorIncidentsInput"

export interface EditMajorIncidentsProps {
  onEdit: (alert: TempServiceAlerts) => void;
}

let MajorIncidents = () => {

  let { is_admin_user } = useCurrentUserInfo()

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState<TempServiceAlerts | undefined>(undefined);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleEdit = (alert: TempServiceAlerts) => {
    setSelectedAlert(alert);
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

    return (
        <PageParent>
            <ToolHeader> Major Incidents</ToolHeader>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Active" />
                <Tab label="Resolved" />
            </Tabs>
            <Box p={3}>
                {selectedTab === 0 && <ActiveMajorIncidents onEdit={handleEdit} />}
                {selectedTab === 1 && <ClosedMajorIncidents onEdit={handleEdit} />}
            </Box>
            { is_admin_user && (
              <Box marginTop="2rem" sx={{ maxWidth: "96%", margin: "0 auto"}} ref={inputRef}>
                <MajorIncidentsInput baseTempAlert={selectedAlert}/>
              </Box>
            )}
        </PageParent>
    );
}

export default MajorIncidents;