import HomePage from "./components/HomePage/HomePage"
import SearchUtil from "./components/Search/Search"
import React from "react"

// Icon Imports
import { Search, Wifi, Settings as SettingsIcon, AdminPanelSettings, Duo, WarningOutlined } from "@mui/icons-material"
import Network from "./components/Network/Network"
import Settings from "./components/Settings/Settings"
import Administration from "./components/Administration/Administration"
import ZoomTicketSection from "./components/ZoomTicket/ZoomTicketSection"
import MajorIncidents from "./components/MajorIncidents/MajorIncidents"

export interface Route {
    path: string
    name: string
    component: React.ComponentType<any>
    link?: string
    icon?: React.ComponentType
    hideOnSideBar?: boolean
}

export const routes: Route[] = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "search/*",
        link: "/search",
        name: "Search",
        component: SearchUtil,
        icon: Search,
    },
    {
        path: "/network",
        name: "Network",
        component: Network,
        icon: Wifi,
    },
    {
        path: "/MajorIncidents",
        name: "Major Incidents",
        component: MajorIncidents,
        icon: WarningOutlined,
    },
    {
        path: "/ZoomTickets",
        name: "Zoom Tickets",
        component: ZoomTicketSection,
        icon: Duo,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        icon: SettingsIcon,
    },
]

export const admin_routes: Route[] = [
    {
        path: "/admin",
        name: "Administration",
        component: Administration,
        icon: AdminPanelSettings,
    }
]

// export default routes
